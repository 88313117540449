import React from "react";
import Helmet from "react-helmet";

interface MetaProviderProps {
  meta: {
    [key: string]: any;
  },
}

function MetaProvider(props: MetaProviderProps) {
  const siteTitle = 'Fired Up';

  const pageTitle = `${
    props.meta.title ? props.meta.title + ' | ' : ''
  } ${siteTitle}`;

  return (
    <Helmet title={pageTitle}>
      <meta content="noindex" property="robots" />
      <link
        href="https://use.fontawesome.com/releases/v5.8.1/css/all.css"
        rel="stylesheet"
      />
      <meta name="theme-color" content="#ffffff" />
    </Helmet>
  );
}

MetaProvider.getInitialProps = async ({ req }) => {
  if (req) {
    Helmet.renderStatic();
  }

  return {};
}

export default MetaProvider;
